import styled from "styled-components";
import i18n from "../../../../i18n";

const STYLED_UPPER_NAV = styled.article`
    width: 100%;
    height: 30px;

    background: rgb(241, 241, 241);
    background: -moz-linear-gradient(
        90deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(227, 227, 227, 1) 50%,
        rgba(241, 241, 241, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(227, 227, 227, 1) 50%,
        rgba(241, 241, 241, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(227, 227, 227, 1) 50%,
        rgba(241, 241, 241, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f1f1",endColorstr="#f1f1f1",GradientType=1);

    div {
        width: 100px;
        height: 100%;
        float: right;
        @media screen and (max-width: 576px) {
            width: fit-content;
            margin: 0 auto;
            float: unset;
        }

        & span {
            color: #505050;
            line-height: 30px;
            font-size: 15px;
            font-weight: bold;
            @media screen and (min-width: 576px) {
                cursor: pointer;
            }
        }
    }
`;

export default function UpperNav() {
    const locales = {
        ge: { title: "GE" },
        en: { title: "EN" },
        ru: { title: "RU" },
    };

    const handleChangeLang = (language) => {
        // check if the selected language is different from the current language
        if (i18n.language !== language) {
            // change the language using i18next's changeLanguage method
            i18n.changeLanguage(language);
        }
    };

    const activeLang = i18n.language;

    return (
        <STYLED_UPPER_NAV $activeLang={activeLang}>
            <div>
                <span
                    onClick={() => handleChangeLang("ge")}
                    style={
                        activeLang === "ge"
                            ? { color: "#da4900" }
                            : { color: "#505050" }
                    }
                >
                    {locales.ge.title}
                </span>{" "}
                |{" "}
                <span
                    onClick={() => handleChangeLang("en")}
                    style={
                        activeLang === "en"
                            ? { color: "#da4900" }
                            : { color: "#505050" }
                    }
                >
                    {locales.en.title}
                </span>{" "}
                |{" "}
                <span
                    onClick={() => handleChangeLang("ru")}
                    style={
                        activeLang === "ru"
                            ? { color: "#da4900" }
                            : { color: "#505050" }
                    }
                >
                    {locales.ru.title}
                </span>
            </div>
        </STYLED_UPPER_NAV>
    );
}
