import styled, { keyframes, css } from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UpperNav from "./UpperNav/UpperNav";

const slideDownBurger = keyframes`
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(40%);
  }
  to {
    transform: translateY(0%);
  }
`;

const STYLED_HEADER = styled.header`
    display: block;
    float: left;
    position: fixed;
    width: 100%;
    height: 120px;
    z-index: 90;

    .upper-nav {
        width: 100%;
        height: 30px;

        background: rgb(207, 207, 207);
        background: -moz-radial-gradient(
            circle,
            rgba(207, 207, 207, 1) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        background: -webkit-radial-gradient(
            circle,
            rgba(207, 207, 207, 1) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        background: radial-gradient(
            circle,
            rgba(207, 207, 207, 1) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cfcfcf",endColorstr="#ffffff",GradientType=1);
    }

    .lower-nav {
        width: 100%;
        height: 90px;
        background-color: #ffffff;
        animation: ${({ $isvisible }) => ($isvisible ? slideDown : slideUp)};
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        border-bottom: 3px solid #da4900;
        box-shadow: 0px 3px 5px 4px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 3px 5px 4px rgba(0, 0, 0, 0.3);
        @media screen and (max-width: 576px) {
            height: 80px;
        }
        /* 
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 3px;
            bottom: 0;
            background-color: #757575;
            background-color: #DA4900;
            box-shadow: 0px 3px 5px 4px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: 0px 3px 5px 4px rgba(0, 0, 0, 0.3);
        } */

        & .container {
            width: 80%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* media query 992px */
            @media screen and (max-width: 992px) {
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                width: 90%;
            }
            /* media query 576px */
            @media screen and (max-width: 576px) {
                flex-direction: row;
                justify-content: space-between;
                gap: unset;
            }
            @media screen and (max-width: 360px) {
                width: 97%;
            }

            /* logo */
            #logo-link {
                text-decoration: none;
                h2 {
                    font-family: "Montserrat", sans-serif;
                    color: #525F70;
                    text-transform: uppercase;
                    cursor: pointer;
                    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
                    @media screen and (min-width: 768px) {
                        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
                    }

                    @media screen and (max-width: 325px) {
                        font-size: 22px;
                    }
                }
            }

            /* burger menu */
            .burger-menu {
                display: none;
                cursor: pointer;
                width: 38px;
                height: 38px;
                background-color: transparent;
                @media screen and (max-width: 576px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;
                }

                & .burger-bar {
                    width: 100%;
                    height: 8px;
                    background-color: #393333;
                    border-radius: 10px;
                    box-sizing: border-box;
                    /* animations for burger - clicked */
                    &.clicked:nth-child(1) {
                        transform: rotate(45deg) translate(0.85em, 0.4em);
                        transition: ease-out 0.5s;
                    }
                    &.clicked:nth-child(2) {
                        transform: scale(0);
                        transition: ease-out 0.5s;
                    }
                    &.clicked:nth-child(3) {
                        transform: rotate(135deg) translate(-0.88em, 0.5em);
                        transition: ease-out 0.5s;
                    }
                    /* animations for burger - unclicked */
                    &.burger-bar.unclicked {
                        transform: rotate(0) translate(0);
                        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
                    }
                }
            }

            & ul {
                display: flex;
                gap: 27px;
                list-style: none;
                /* media query 768px */
                @media screen and (max-width: 768px) {
                    gap: 14px;
                }
                /* media query 576px */
                @media screen and (max-width: 576px) {
                    ${({ $isburgermenu }) =>
                        $isburgermenu
                            ? css`
                                  padding-block: 14px;
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  gap: 28px;
                                  position: absolute;
                                  top: 80px;
                                  left: 0;
                                  width: 100%;
                                  height: fit-content;
                                  background-color: rgb(255, 255, 255);
                                  animation: ${slideDownBurger} 0.5s ease;
                                  z-index: 15;
                                  border-bottom: 1px solid gray;
                              `
                            : css`
                                  display: none;
                              `};
                }

                & li {
                    position: relative;

                    & a {
                        font-family: "pbgMtavruli", sans-serif;
                        font-size: 15.5px;
                        font-weight: bold;
                        letter-spacing: 0.5px;
                        text-decoration: none;
                        color: #505050;
                        cursor: pointer;
                        transition: 0.4s ease-in-out;
                        /* media query 768px */
                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                        }
                        /* media query 576px */
                        /* @media screen and (max-width: 576px) {
                            font-size: 14px;
                        } */

                        &.active {
                            position: relative;
                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                z-index: 70;
                                bottom: -9px;
                                left: 0;
                                width: 100%;
                                height: 3px;
                                border-radius: 14px;
                                background: linear-gradient(
                                    to right,
                                    #4f2fff,
                                    #ffbe8d
                                );
                            }
                        }
                    }

                    &:hover {
                        color: #2c2c2c;
                        &::after {
                            width: 100%;
                        }
                    }
                    /* linear-gradient under li's on hover */
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        z-index: 65;
                        bottom: -9px;
                        left: 0;
                        width: 0;
                        height: 3px;
                        border-radius: 14px;
                        background: linear-gradient(to right, #4f2fff, #ffbe8d);
                        transition: 0.25s ease-in-out;
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
`;

export default function Header() {
    // first nav & sec. nav & header visibility --------------
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);

    const handleScroll = () => {
        let lowerNav = document.querySelector(".lower-nav");
        let header = document.querySelector("header");
        if (window.scrollY > 30) {
            // hiding upper-nav after scrolling down
            setIsHeaderVisible(false);
            // making lower-nav transparent
            lowerNav.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
            lowerNav.style.backdropFilter = "blur(5px)";
            lowerNav.style.height = "80px";
            header.style.height = "80px";
        } else {
            // returning to normal
            lowerNav.style.backgroundColor = "#ffffff";
            lowerNav.style.height = "80px";
            header.style.height = "120px";
            setIsHeaderVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // -------------------------------------------------------
    // burger menu visibility --------------------------------
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const [burgerClass, setBurgerClass] = useState("burger-bar unclicked");

    const handleBurgerMenu = () => {
        setIsBurgerOpen((prev) => !prev);
        setBurgerClass((prev) =>
            prev === "burger-bar clicked"
                ? "burger-bar unclicked"
                : "burger-bar clicked"
        );
    };

    // scroll to top on click ----------------------------------------
    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
        });
        // closing the burger menu when window width is less than 576px
        if (window.innerWidth <= 576) {
            setIsBurgerOpen(false);
            setBurgerClass("burger-bar unclicked");
        }
    };

    // preload a page only when the user hovers over a NavLink -------
    const [preloaded, setPreloaded] = useState(false);

    const handleMouseEnter = () => {
        if (!preloaded) {
            preloadPage("AboutUs", "AboutUsPage");
            preloadPage("Courses", "CoursesPage");

            setPreloaded(true);
        }
    };

    const preloadPage = useCallback((pageFolder, pageComponent) => {
        // dynamically import page and trigger preload
        import(`../../../pages/${pageFolder}/${pageComponent}`);
    }, []);

    useEffect(() => {
        // cleaning up the preloaded state when the component is unmounted
        return () => setPreloaded(false);
    }, []);

    // - translation -----------------------------------------------------
    const { t } = useTranslation();

    return (
        <STYLED_HEADER
            $isvisible={isHeaderVisible}
            $isburgermenu={isBurgerOpen}
        >
            {/* upper navigation with language switch */}
            {isHeaderVisible && <UpperNav />}

            <nav className="lower-nav">
                <div className="container">
                    <Link id="logo-link" to="/">
                        <h2 onClick={handleScrollTop}>HAULMENTORS.COM</h2>
                    </Link>
                    <div className="burger-menu" onClick={handleBurgerMenu}>
                        <div className={burgerClass}></div>
                        <div className={burgerClass}></div>
                        <div className={burgerClass}></div>
                    </div>
                    <ul>
                        <li>
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                onClick={handleScrollTop}
                            >
                                {t("main.header.categories.main")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/about-us"
                                onClick={handleScrollTop}
                                onMouseEnter={handleMouseEnter}
                            >
                                {t("main.header.categories.about-us")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/dispatcher-courses"
                                onClick={handleScrollTop}
                                onMouseEnter={handleMouseEnter}
                            >
                                {t("main.header.categories.courses")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/business-deals"
                                onClick={handleScrollTop}
                            >
                                {t("main.header.categories.forbusinesses")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </STYLED_HEADER>
    );
}
