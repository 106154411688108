import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import "./index.css";
import ReactGA from "react-ga4";

// initializing Google Analytics
ReactGA.initialize("G-WLCP13NYJF");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
        <App />
    // </React.StrictMode>
);