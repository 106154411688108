import styled from "styled-components";

const STYLED_SPINNER = styled.article`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    & .loader {
        width: 48px;
        height: 48px;
        border: 3px solid #fff;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    & .loader::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-bottom-color: #ff3d00;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default function Spinner() {
    return (
        <STYLED_SPINNER>
            <span className="loader"></span>
        </STYLED_SPINNER>
    );
}
