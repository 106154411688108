import styled, { keyframes } from "styled-components";

import phone_img from "../../../assets/imgs/phone.png";
import email_img from "../../../assets/imgs/mail.png";

// soc. media icons
import fb_logo from "../../../assets/imgs/soc_media/fb_icon.png";
import ig_logo from "../../../assets/imgs/soc_media/ig_icon.png";
import tt_logo from "../../../assets/imgs/soc_media/tt_icon.png";

// translation
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------------------
const ANIMATE_SOC = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
`;

const STYLED_FOOTER = styled.footer`
    float: left;
    width: 100%;
    height: auto;
    border-top: 3px solid #da4900;
    box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.75);

    background: rgb(241, 241, 241);
    background: -moz-linear-gradient(
        90deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(221, 221, 221, 1) 50%,
        rgba(241, 241, 241, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(221, 221, 221, 1) 50%,
        rgba(241, 241, 241, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(221, 221, 221, 1) 50%,
        rgba(241, 241, 241, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f1f1",endColorstr="#f1f1f1",GradientType=1);

    & .footer-body {
        margin: 0 auto;
        width: 75%;
        height: auto;
        padding: 8px 20px;

        @media screen and (max-width: 992px) {
            width: 90%;
        }

        & .f-body-left {
            float: left;
            width: 36%;
            margin: auto;
            /* media query for 768px */
            @media screen and (max-width: 768px) {
                width: fit-content;
            }
            /* media query for 470px */
            @media screen and (max-width: 470px) {
                float: unset;
                clear: both;
                margin: 0 auto;
            }

            & ul {
                list-style-type: "›";
                list-style-position: inside;
                color: #222222;
                font-size: 14px;
                text-shadow: 0px 0px 1px #505050;
                -webkit-text-shadow: 0px 0px 1px #505050;

                /* media query for 470px */
                @media screen and (max-width: 470px) {
                    text-align: center;
                }

                & li {
                    margin-block: 8px;
                    a {
                        text-decoration: none;
                        color: #0e0e0e;
                        font-weight: 600;
                        font-size: 14px;

                        /* li-a hover */
                        &:hover {
                            color: #303030;
                        }
                    }
                }
            }
        }

        /* contact info */
        & .f-body-center {
            float: left;
            width: 28%;
            margin: 0 auto;

            /* media query for 768px */
            @media screen and (max-width: 768px) {
                float: right;
                width: fit-content;
            }
            /* media query for 470px */
            @media screen and (max-width: 470px) {
                clear: both;
                float: unset;
                margin: 24px auto 12px auto;
                position: relative;

                & :before {
                    content: "";
                    position: absolute;
                    top: -12px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: gray;
                }
            }

            & .body-right-upper {
                & .right-row-i {
                    display: block;
                    margin-top: 8px;

                    & figure {
                        display: block;
                        text-align: center;
                        img {
                            margin: 0 auto;
                            width: 20px;
                            height: 20px;
                        }
                    }
                    & span {
                        margin-block: 4px;
                        display: block;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        color: #000000;
                    }
                }
                & .sm-divider {
                    width: 45%;
                    height: 1px;
                    margin: 6px auto;
                    background: rgb(143, 143, 143);
                    background: -moz-linear-gradient(
                        90deg,
                        rgba(143, 143, 143, 0.5) 0%,
                        rgba(110, 110, 110, 1) 50%,
                        rgba(143, 143, 143, 0.5) 100%
                    );
                    background: -webkit-linear-gradient(
                        90deg,
                        rgba(143, 143, 143, 0.5) 0%,
                        rgba(110, 110, 110, 1) 50%,
                        rgba(143, 143, 143, 0.5) 100%
                    );
                    background: linear-gradient(
                        90deg,
                        rgba(143, 143, 143, 0.5) 0%,
                        rgba(110, 110, 110, 1) 50%,
                        rgba(143, 143, 143, 0.5) 100%
                    );
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8f8f8f",endColorstr="#8f8f8f",GradientType=1);
                }
            }
        }

        & .f-body-soc-edge {
            float: left;
            padding: 8px;
            width: 36%;
            text-align: right;
            /* media query for 768px */
            @media screen and (max-width: 768px) {
                clear: both;
                margin: 0 auto;
                float: unset;
                width: fit-content;
            }

            & a {
                & figure {
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    margin-inline: 6px;
                    img {
                        width: 100%;
                        @media screen and (min-width: 768px) {
                            /* transition: 0.4s ease; */
                            &:hover {
                                animation: ${ANIMATE_SOC} 0.8s forwards;
                            }
                        }
                    }
                }

                /* icon in the middle */
                &:nth-child(2){
                    opacity: 1;
                }
            }
        }

        & .f-body-bottom {
            clear: both;
            & p {
                color: #333333;
                font-size: 14px;
                padding-top: 8px;
                border-top: 2px solid gray;
                text-align: center;
                font-family: "pbgMtavruli", sans-serif;
            }
        }
    }
`;

export default function Footer() {
    const { t } = useTranslation();

    return (
        <STYLED_FOOTER>
            {/* <div className="footer-head"></div> */}
            <div className="footer-body">
                <div className="f-body-left">
                    <ul>
                        <li>
                            <a
                                href="https://www.copart.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Copart
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.adesa.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Adesa
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.carmax.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Carmax
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://site.manheim.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Manheim
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.iaai.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                IAAI
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.carvana.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Carvana
                            </a>
                        </li>
                        {/* <li>
                            <a
                                href="https://www.giorgigok.github.io/dispatching-rate-calculator-js/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Rate Calculator
                            </a>
                        </li> */}
                    </ul>
                </div>
                {/* contact info */}
                <div className="f-body-center">
                    <div className="body-right-upper">
                        <div className="right-row-i">
                            <figure>
                                <img src={phone_img} alt="phone" />
                            </figure>
                            <span> +(995) 568 639 861</span>
                            <span> +(995) 595 582 115</span>
                            <span> +1(716) 270 5513</span>
                        </div>
                        <div className="sm-divider"></div>
                        <div className="right-row-i">
                            <figure>
                                <img src={email_img} alt="email" />
                            </figure>
                            <span>haulmentors@outlook.com</span>
                        </div>
                    </div>
                </div>
                {/* soc. media */}
                <div className="f-body-soc-edge">
                    <a
                        href="https://www.facebook.com/people/HaulMentorscom/61555683647181/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <figure>
                            <img src={fb_logo} alt="facebook" />
                        </figure>
                    </a>

                    <a
                        href="https://www.instagram.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <figure>
                            <img src={ig_logo} alt="instagram" />
                        </figure>
                    </a>

                    <a
                        href="https://www.tiktok.com/@haulmentors"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <figure>
                            <img src={tt_logo} alt="tik-tok" />
                        </figure>
                    </a>
                </div>
                <div className="f-body-bottom">
                    <p>{t("footer.copyright")}</p>
                </div>
            </div>
        </STYLED_FOOTER>
    );
}
